.button-group-custom .btn-action {
    position: relative;
}

.button-group-custom .btn-action:not(:last-child)::after {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    height: 50%;
    border-right: 1px solid #ccc; /* Customize the color of the vertical line */
    transform: translateY(-50%);
}