/* styles.css */
.header {
    background-color: #1D3557;
    color: #FFFFFF;
    padding: 10px;
    position: sticky;
    top: 0;
    z-index: 1000;
}

.img-logo img {
    display: flex;
    margin: 0px 20px 0px 20px;
    width: 150px;
    height: auto;
}

.card-primary {
    background-color: #7cafce;
    color: #000000;
    width: 280px;
}

.card-primary > .card-body {
    padding: 8px 0px 5px 15px;
}

.card-body {
    padding: 10px 0px 10px 12px;
}

.card-footer {
    padding: 5px 10px 5px 10px;
}

.btn-action {
    background-color: #ffffff; /* Light Blue */
    color: #1D3557; /* Dark Blue Text */
    border-color: #ffffff; /* Light Blue */
    transition: background-color 0.3s, border-color 0.3s;
}

.btn-action:hover:not(:disabled) {
    background-color: #7b9ddb; /* Lighter Blue */
    border-color: #7b9ddb; /* Lighter Blue */
}

.btn-action:disabled {
    background-color: #F5F5F5; /* Very Light Grey */
    border-color: #F5F5F5; /* Very Light Grey */
    color: #9E9E9E; /* Medium Grey */
}

.btn-start {
    background-color: #1D3557;
    color: #ffffff;
    border-color: #1D3557;
    transition: background-color 0.3s, border-color 0.3s;
}

.btn-start:hover:not(:disabled) {
    background-color: #7cafce;
    border-color: #7cafce;
    color: #000000;
}

.container-bg {
    background-color: #eef7fa;
    overflow-x: auto;
    white-space: nowrap;
    padding: 10px;
}

.benefit-positive {
    color: #A8DADC;
}

.benefit-negative {
    color: red;
    font-weight: 400;
}

.undraggable-item {
    background-color: lightgrey;
    margin-top: -8px;
}

.draggable-item {
    background-color: lightgrey;
    cursor: pointer;
    margin-bottom: 8px
}

.draggable-item:hover {
    background-color: #f1f1f1;
}

.draggable-container {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
}
