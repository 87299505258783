.login-container {
    max-width: 400px;
    margin: 50px auto;
    padding: 20px;
    background-color: #dceaf8;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.img-logo-login img {
    display: block;
    margin: 0 auto 20px;
    width: 150px;
    height: auto;
}

.btn-login {
    margin-top: 1rem;
}